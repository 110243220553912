<template>
	
	<form id="wp-login-form" method="post" :action="preview + '/wp-login.php'" target="iframe-preview">
		<input type="hidden" name="log" v-model="login.user">
		<input type="hidden" name="pwd" v-model="login.password">
		<input type="hidden" name="redirect_to" :value="redirect">
		<input type="hidden" name="rememberme" value="forever">
	</form>

</template>

<script>
import { mapGetters } from 'vuex';
import site_service from '@/services/site_service'

export default {
	name: 'components.wp_login',
	props: {
		workspace: Object,
		redirect: String,
		modelValue: [String, Number, Object, Boolean]
	},

	emits: [
		'update:modelValue',
		'update:submitted',
	],

	data() {
		return {
			site: null,
			wp_submitted: false,
			login: {
				user: null,
				password:null,
				database: null
			},
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},
	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		this.get_wp_login()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_wp_login()
		{			
			this.reset_error()

			if ( this.auth_user.type == 'viewer' ) {
				this.wp_submitted = true
				return
			}

			await site_service.wp_login( this.$route.params.workspace_id, this.$route.params.site_id ).then(( response ) => {

				this.login = response.data

				this.wp_login()

			}).catch((error) => {
				this.error = error
			})
		},

		async wp_login()
		{

			setTimeout(() => {
				if ( this.login.user ) {
					var form = document.getElementById( 'wp-login-form' )

					if ( form ) {
						form.submit()
						this.wp_submitted = true
						this.$emit( 'update:submitted', true );
					} else {
						this.wp_login()
					}

				} else {
					this.wp_login()
				}				
			}, 500)
		},
	}
}
</script>

<style>

</style>
