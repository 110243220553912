<template>
	
	<div>

		<div v-if="!has_content" class="card-body p-4">
			<h4>Add content to preview grouping.</h4>
			<p class="mb-0">As you add content we'll automatically group it into sections and let you know which would be the best block for each section.</p>
		</div>

		<div v-else>

			<div v-for="( block, block_key ) in content" :key="'block-' + block_key" class="border-bottom p-4">

				<p class="badge bg-info">Recommended: {{ block.block }} block</p>

				<div class="small">
					<h2 v-if="block.title">{{ block.title }}</h2>
					<p v-if="block.description" v-html="block.description" class="mb-0"></p>

					<div v-if="block.buttons.length">
						<button v-for="(btn, btn_key) in block.buttons" :key="'block-' + block_key + '-btn-' + btn_key" type="button" class="btn btn-outline-primary mt-4 me-3">{{ btn.label }}</button>
					</div>

					<div v-if="block.items.length && block.items_has_description" class="list-group mt-4 position-relative">
						<span class="badge bg-info position-absolute top-0 start-0" style="z-index: 1; margin-top: -8px">Items</span>
						<div v-for="( item, item_key ) in block.items" :key="'block-' + block_key + '-item-' + item_key" class="list-group-item">
							<h4 class="mb-2">{{ item.title }}</h4>
							<p v-if="item.description" v-html="item.description" class="mb-0"></p>

							<button v-if="item.btn_label" type="button" class="btn btn-outline-primary btn-sm mt-4 me-3">{{ item.btn_label }}</button>
							<button v-else-if="item.btn_url" type="button" class="btn btn-outline-primary btn-sm mt-4 me-3">Read More</button>

							<div v-if="item.items_inr && item.items_inr.length" class="position-relative">
								<span class="badge bg-info position-absolute top-0 start-0" style="z-index: 1; margin-top: -8px">Bullet points</span>
								<ul class="list-group mt-4">

									<li v-for="( item, item_key ) in item.items_inr" :key="'block-' + block_key + '-item-' + item_key" class="list-group-item">
										{{ item.title }}
									</li>

								</ul>
							</div>
						</div>
					</div>

					<div v-if="block.items.length && !block.items_has_description" class="position-relative">
						<span class="badge bg-info position-absolute top-0 start-0" style="z-index: 1; margin-top: -8px">Bullet points</span>
						<ul class="list-group mt-4">

							<li v-for="( item, item_key ) in block.items" :key="'block-' + block_key + '-item-' + item_key" class="list-group-item">
								{{ item.title }}
							</li>

						</ul>
					</div>

					<ul v-if="block.is_contact" class="list-group mt-4">

						<li v-if="block.address" class="list-group-item d-flex align-items-center">
							<span class="material-icons-outlined me-2">location_on</span>
							{{ block.address }}
						</li>

						<li v-if="block.email" class="list-group-item d-flex align-items-center">
							<span class="material-icons-outlined me-2">email</span>
							{{ block.email }}
						</li>

						<li v-if="block.phone" class="list-group-item d-flex align-items-center">
							<span class="material-icons-outlined me-2">phone</span>
							{{ block.phone }}
						</li>

					</ul>
				</div>

			</div>

		</div>

	</div>

</template>

<script>
export default {
	name: 'components.html_to_block',
	props: {
		original_content: String,
		content: [Object, Array, String],
	},
	computed: {
		has_content()
		{
			return this.original_content ? this.original_content.replace(/(<([^>]+)>)/gi, '') : null
		}
	}
}
</script>

<style>

</style>
