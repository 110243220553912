<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<wp_login v-if="page" v-model="site" :workspace="workspace" :redirect="wireframe_preview" v-on:update:submitted="update_submitted" />

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<design_nav :site="site" :allow_generate="true" />

				<div v-if="page" class="w-100 h-100 position-relative overflow-hidden d-flex">
					<div :style="!hide_settings ? 'flex: 0 0 325px;' : 'flex: 0 0 10px;'">

						<div class="h-100 border-end position-relative" style="z-index: 1">

							<button class="btn bg-light d-inline-flex align-items-center position-absolute p-1 border border-start-0 rounded-start-0" v-on:click="[hide_settings = ( hide_settings ? false : true )]" style="top: -1px; right: -17px; z-index: 2;">
								<span class="material-icons-outlined">{{ hide_settings ? 'chevron_right' : 'chevron_left' }}</span>
							</button>
							
							<div v-if="!hide_settings" class="w-100 h-100 overflow-auto p-4">

								<div class="card">
									<html_to_block :content="page.original_content_formatted" :original_content="page.original_content" />
								</div>
							</div>
						</div>
					</div>

					<div class="bg-white overflow-hidden flex-grow-1 position-relative" style="padding-bottom: 50px;">
						<div class="position-absolute top-50 start-50 translate-middle">
							<span class="spinner-border" role="status">
								<span class="visually-hidden">Loading...</span>
							</span>
						</div>

						<iframe 
						v-on:load="load_iframe"
						name="iframe-preview" 
						class="w-100 h-100 border-bottom position-relative" 
						:class="[(!iframe_loaded || !wp_submitted ? 'opacity-0' : '')]"
						></iframe>
					</div>					

				</div>

			</div>

		</div>

		<div class="modal fade" id="generate" tabindex="-1" aria-labelledby="shareLabel" aria-hidden="true">

			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header bg-primary border-0">
						<h5 class="modal-title" id="shareLabel"></h5>
						<button type="button" class="text-white border-0 bg-transparent" data-bs-dismiss="modal" aria-label="Close">
							<span class="material-icons-outlined">close</span>
						</button>
					</div>
					<div class="modal-body p-0 bg-primary text-white">

						<div class="card-body pe-4 p-0 d-flex align-items-center justify-content-between">

							<div class="me-2">
								<div class="mx-auto overflow-hidden" style="width: 220px; height: 220px; margin-top: -50px">
									<img src="@/assets/img/leo.png" class="img-fluid">
								</div>
							</div>

							<div>
								<h2 class="h3">Do you want me to regenerate this page?</h2>

								<div class="mb-2">
									<btn_submit 
										label="Yes please" 
										:icon_after="true" 
										:loading="loading" 
										v-on:click="generate_wireframe()"
									/>
								</div>
							</div>

						</div>
						<div class="card-body border-top p-3" style="border-color: rgba( 255, 255, 255, 0.2 ) !important;">
							<div>

								<div class="alert alert-secondary small mb-0">
									<p class="mb-0"><span class="fw-bold">Warning:</span> This will override any changes you have made.</p>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import design_nav from '@/components/design_nav'
import html_to_block from '@/components/html_to_block'
import btn_submit from '@/components/btn_submit'
import wp_login from '@/components/wp_login'
import page_service from '@/services/page_service'

export default {
	name: 'site.design.page',

	props: {
		modelValue: [Object],
		workspace: [Object],
	},

	emits: [
		'update:modelValue',
	],

	components: {
		design_nav,
		html_to_block,
		wp_login,
		btn_submit
	},

	data() {
		return {
			loading: false,
			wp_submitted: false,
			iframe_loaded: false,
			site: null,
			page: null,
			version: 0,
			hide_settings: true
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		preview()
		{
			var url = 'https://'

			if ( this.workspace ) {
				url += this.workspace.domain + '/storage/'
			}

			if ( this.site ) {
				url += this.site.slug
			}

			return url
		},

		wireframe_preview()
		{
			var wireframe = ( this.site['settings'] && this.site['settings']['override-wireframe'] ? '' : 'true' )

			wireframe = ''

			wireframe += '&areoi-view=design'

			var url = this.preview

			if ( this.page ) {
				url += '/wp-admin/post.php?post=' + this.page.ID + '&verion=' + this.version + '&action=edit&wireframe=' + wireframe
			}

			return url
		},

	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		this.get_page()
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		async get_page()
		{			
			this.reset_error()

			this.loading = true

			await page_service.get_wordpress_page( this.$route.params.workspace_id, this.$route.params.site_id, this.$route.params.page_id ).then(( response ) => {

				this.page = response.data

				if ( !this.page.original_content ) {
					this.hide_settings = true
				}

			}).catch((error) => {
				this.error = error
			}).finally(() => {
				this.loading = false
			})
		},

		generate_wireframe()
		{
			this.loading = true 

			this.reset_error()

			page_service.generate_wireframe( this.$route.params.workspace_id, this.$route.params.site_id, this.page ).then(() => {

				this.version++

				var $ = window.$
				$( '[name="iframe-preview"]' ).attr( 'src', this.wireframe_preview );

				$( '.modal' ).modal( 'hide' );

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {
				setTimeout( () => {
					this.loading = false
				}, 2000)
			})
		},

		update_submitted( e )
		{
			this.wp_submitted = e
		},

		load_iframe()
		{
			this.iframe_loaded = true;
		},
	}
}
</script>